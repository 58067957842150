<!-- eslint-disable max-len -->
<template>
  <button
    @mouseover="hovered = true && !screen.touch"
    @mouseleave="hovered = false"
    :type="type"
    :class="{ 'btn-round--hover': hovered }"
    class="btn btn-round border-blue border-2 rounded-full shadow-xl hover:shadow-2xl"
  >
    <div
      class="btn-round__inner bg-black rounded-full shadow-xl hover:shadow-2xl overflow-hidden relative"
    >
      <div
        class="btn-round__inner__contents flex-flex-col flex-no-wrap h-full w-full"
      >
        <i
          :class="iconClass"
          class="text-white text-2xl flex flex-no-shrink justify-center items-center w-full h-full"
        ></i>
        <div
          class="helper-text din text-white flex flex-no-shrink justify-center items-center w-full h-full"
          v-html="hoverText"
        ></div>
      </div>
    </div>
  </button>
</template>

<script>
export default {
  name: "LondoButtonRound",
  props: {
    icon: {
      type: String,
      default: "camera-1",
      required: false,
    },
    hoverText: {
      type: String,
      default: "Play<br />Video",
    },
    type: {
      type: String,
      default: "button",
    },
  },
  data() {
    return {
      hovered: false,
    };
  },
  components: {},
  computed: {
    iconClass() {
      return `icon-${this.icon}`;
    },
  },
  watch: {
    hovered(val) {
      this.$emit("hovered", val);
    },
  },
};
</script>

<style scoped lang="scss">
.btn-round {
  font-size: 1rem;
  width: 5em;
  height: 5em;
  transition: 0.2s border-color ease-in;
  &__inner {
    width: 3.75em;
    height: 3.75em;
    &__contents {
      transition: 0.2s margin ease-out;
      .helper-text {
        font-size: 13px;
        line-height: 0.938em !important;
      }
    }
  }
  &.btn-round--hover {
    border-color: white;
    transition: 0.2s border-color ease-out;
    .btn-round__inner__contents {
      margin-top: -100%;
      transition: 0.2s margin ease-in;
    }
  }
}
</style>
